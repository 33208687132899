import logo from './logo.svg';
import './App.css';
import clockIcon from  './img/clock-icon.png'
import cheersIcon from  './img/cheers.png'
import ringsIcon from  './img/rings-icon.png'
import dinnerIcon from  './img/dinner.png'

function App() {

  return (
    <div>
      <div className="container"></div>
      <div className="green"></div>
      <div className="underlay"></div>
    <div className="App">
      <div className="ja">
        - Wij gaan trouwen -
      </div>
      <div className="menuContainer">
        <ul className='menu'>
          <li><a href="#programma">Programma</a></li>
          <li><a href="#locatie">Locatie</a></li>
          <li><a href="#dieetwensen">RSVP / Dieetwensen</a></li>
          <li><a href="#cadeau">Cadeauwens</a></li>
        </ul>
      </div>
      <div className="sectionContainer">
        <div className="sectionInner">
        <a name="programma" />
        <h1>Programma</h1>
        <div>
          <ul className="programmaUl">
            <li>
              <div className="listIcon"><img src={clockIcon} /></div><div> 15.30 uur ontvangst</div></li>
            <li><div className="listIcon"><img src={ringsIcon} /></div><div> 16.15 uur ceremonie</div></li>
            <li><div className="listIcon"><img src={cheersIcon} /></div><div> daarna proosten</div></li>
            <li><div className="listIcon"><img src={dinnerIcon} /></div><div> vervolgens diner en muziek</div></li>
            <li><div className="listIcon"><img src={clockIcon} /></div><div> rond 21.30 uur einde</div></li>
          </ul>
        </div>
        </div>
      </div>
      <div className="sectionContainer locationSection">
      <div className="sectionInner">
        <a name="locatie" />
        <h1>Locatie</h1>
        <div className="locationContainer">
          <div className="locationText">
            <table className="locationTable">
              <tr>
                <td><strong>Bereikbaarheid Park Vijversburg:</strong></td>
                <td><a href="https://vijversburg.nl/informatie/" target="_blank">Informatie - Park Vijversburg</a></td>
              </tr>
              <tr>
                <td><strong>Route Park Vijversburg:</strong></td>
                <td><a href="https://maps.app.goo.gl/mNmaYpRVbuRiaPSr5" target='_blank'>Google Maps</a></td>
              </tr>
              <tr>
                <td><strong>Parkeren Park Vijversburg:</strong></td>
                <td>je kunt parkeren bij de hoofdingang. 
          Houd de bel bij de intercom 5 seconden ingedrukt en geef bij de intercom aan dat je voor onze bruiloft komt. 
          De deur wordt dan voor je geopend. 
          Je vindt ons bij het paviljoen. Bordjes wijzen je de weg. Het is een kleine 10 minuten lopen naar het paviljoen.</td>
              </tr>
            </table>
          </div>
          </div>
          <div className="address">
          In Park Vijversburg lopen een aantal pauwen los rond. Als jij ze met rust laat, laten ze jou ook met rust.<br /><strong>Voer ze vooral niet!</strong><br />
            <br />Swarteweisein 2 - 9255 JB - Tytsjerk</div>
        </div>
      </div>
      <div className="sectionContainer">
      <div className="sectionInner">
        <a name="dieetwensen" />
        <h1>RSVP / Dieetwensen</h1>
        <div>
          Laat je voor 1 september weten of je komt door <a href="https://forms.office.com/e/wMNGrfeat8" target="_blank">dit formulier</a> in te vullen? Hier kun je ook eventuele dieetwensen/allergieën aangeven. 
        </div>
        </div>
      </div>
      <div className="sectionContainer">
      <div className="sectionInner">
        <a name="cadeau" />
        <h1>Cadeauwens</h1>
        <div>
        Jouw aanwezigheid is voor ons cadeau genoeg. Mocht je toch iets willen geven, dan is een bijdrage voor een blijvende herinnering aan deze dag in onze tuin gewenst. 
        </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default App;
